import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import jiva_logo from '../../assets/images/projects/service/tech/jiva-logo.png'
import pic04 from '../../assets/images/projects/23.png'
import resume from '../../assets/CV/blueprint-casestudy.pdf'
import { useRef } from 'react'






const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (

  <Layout>
    <Helmet>
      <title>Service Design - Service Blueprint Workshop</title>
      <meta name="Service Design - Service Blueprint Workshop" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <p className="maisontags">Facilitation, Research, Service Design, UX & Business Strategy</p>
            
            <h1>From Silos to Synergy: How a Service Blueprint Workshop broke barriers for better collaboration and transformation across the org.</h1>

            <h3>
            I facilitated a Service Blueprint Workshop at Jiva to create cross-team awareness and provide
             holistic visibility of current processes. The workshop highlighted user needs and pain points
              while revealing challenges faced by the internal service delivery team. 
            </h3>
            <h3>
            The workshop led to a strategic pivot, redefining the organisation's direction, goals, and four key areas of focus.
            </h3>
            <br />
          

          </header>


          <section className="header-post">
            <div className="headerpost">
            {/* <div className="headerpost-left">
                
            <p>
                A summarised version of the project is presented here. 
                <br /> For a much more in-depth information, feel free to download the detailed case study document for this project.
                <br />
                <div className="resume-download">
                <a href={resume} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
                📘 Download case study</a>
                </div> 
                </p>
              </div> */}

              {/* <div className="headerpost-right">
                <div className="client-image">
                  <img className="span-4" src={jiva_logo} alt="things logo" />
                </div>
                <br />
                <p>
                I worked on this project while working at Jiva Ag, an AgriTtech product company.
                <br />
                <br />
                <a href="/about-jiva"><u>know more about Jiva</u></a>
                </p>
              </div>             */}

            </div>
          </section>

          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <p>
                Jiva set ambitious targets for 2023. By mid-year, our progress fell short of expectations.
               <br />
               <br />
               This prompted us to pause, evaluate our shortcomings, and identify areas for improvement.
                </p>
                <p>
                <a href="/about-jiva"><u>know more about Jiva's stakeholder ecosystem</u></a>
                </p>
          </div>
          <div className="process-right">
              <h4>
              Industry              
              </h4>
              <span className="credits-text">
              AgriTech
              </span>
              <br />
              <h4>
              My role
              </h4>
              <span className="credits-text">
              🔍 The Researcher, 🎯 the UX Strategist, ✍️ the Service Experience Designer, 🦮 the Facilitator, and 🎬 the Orchestrator.
              <br />
              <br />
              I collaborated with the Leadership team, Field-ops teams, Operations leads, Data Science team, Product Managers, Growth & Training teams, Researchers and Customer Support team.
              </span>
              <br />
              </div>
            </div>

          


          <section className="challenge-section">
            {/* <span className="project-step-number">01</span>
            <h2>the challenge</h2> */}

            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
            </div>
            </section>

            <div className="challenge">

            {/* <div className="process">           
              <div className="process-right">
              <h4>
                Triggers to hit the pause button.
              </h4>
              </div>
            </div> */}


            <div className="process">           
              <div className="process-left">
              <p className="half-textlarge">
              Teams working in isolation exacerbated these problems. 
              {/* How do we ensure collective & productive efforts towards a common goal? */}
              </p>
              </div>
              <div className="process-right">
                {/* <h4>
                Triggers to hit the pause button.
                </h4> */}
                {/* <p className="half-textlarge">
                Teams working in isolation exacerbated these problems. 
                </p> */}
                <p>
                We needed to better address user needs while improving 
                our internal teams' efficiency and effectiveness in 
                service delivery.
                </p>
                <p>
                How do we ensure collective & productive efforts towards a common goal?
                </p>
            </div>
            </div>
            <section className="fullspan">
            <div className="image">
              <Img fluid={props.data.tecnechallenge2.childImageSharp.fluid} />
            </div>
            </section>
            </div>

            </section>

            
              

            {/* <div className="image">
              <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
            </div> */}

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

          
          <section className="process-section">
            {/* <span className="project-step-number">02</span>
            <h2>the approach</h2> */}

            {/* <br />
            <br />
            <br />
            <br /> */}

            {/* <div className="process-image">
              <Img fluid={props.data.tecne_process.childImageSharp.fluid} />
            </div> */}

            <div className="changebg">
            <div className="image">
              <Img fluid={props.data.tecneprocess14.childImageSharp.fluid} />
            </div> 
            {/* <div className="process">           
            
              <div className="process-right">
              
              <p className="half-textlarge">
              What we needed was to understand the big picture
               and join the dots across org, barriers & silos 
               and envision better service. 
              </p>               
              </div>
            </div> */}

            <div className="image">
              <Img fluid={props.data.tecneprocess2.childImageSharp.fluid} />
            </div>           
            
            


            <div className="process">  
            <div className="process-left">
            <p className="half-textlarge">
              It was imperative to do this exercise together with all the teams in Jiva; — a workshop.
            </p> 
            </div>
                   
              <div className="process-right">
              <h4>Bring awareness & visibility</h4>  
              <p>
              to the 
              challenges (pains) faced by MCs across 
              the entire experience.
              </p> 
              <h4>Identify possible blind spots </h4>  
              <p>
              that might have missed out which were preventing us from 
               reaching our goals.
              </p> 
              <h4>Prioritise focus areas</h4>  
              <p>
              to work on 
              in the next 3-6 months to create a positive
              change in the MCs' journey with us.
              </p>           
              </div>
            </div>
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>We started by bringing in different perspectives; we looked outwards and inwards.</h4>
            </div>
              
            </div>

            

            <div className="image">
              <Img fluid={props.data.tecneprocess3.childImageSharp.fluid} />
            </div>
            

            <div className="process"> 
            <div className="process-left">
            <p className="half-textlarge">
            We analysed inbound and outbound calls by the Customer Support Team.</p>
            </div>
            <div className="process-right">
            <p className="half-textlarge">
            And gathered quant data from Data Science Team to define the extent of the pain point.</p>
            </div>
            </div>
          
              {/* <div className="process-right">
              <p>
              Quantitative data from Data Science Team to quantify the extent of the pain point or impact. 
              Analysed inbound and outbound calls by the Customer Support Team.
              Insights from previous research by Design Research Team.
              </p>     
              </div> */}


            <div className="changebg">
            {/* <div className="process"> 
            <div className="process-left">
            <p className="half-textlarge">
            We analysed inbound and outbound calls by the Customer Support Team.</p>
            </div>
            <div className="process-right">
            <p className="half-textlarge">
            And gathered quant data from Data Science Team to define the extent of the pain point.</p>
            </div>
            </div> */}

            <p className="full-textlarge">
            This resulted in a comprehensive customer journey from the MC's perspective. 
            <br />
            <br />
            We also synthesised and added the impact these pains have on the MCs, our internal teams and Jiva’s business. 
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
            </div>

            <div className="process">
            <div className="process"> 
            <div className="process-left">
            <p className="half-textlarge">
            Service Blueprint; the anchor for the workshop.</p>
            </div>  
            
              <div className="process-right">
              <br />
              <p>
              It covered all the phases of the user’s journey with Jiva; from Awareness, Recruitment, 
              and Onboarding, to completing input & offtake transactions, solving queries and getting trained on new product updates.
              </p>     
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
            </div>

            </div>
            </div>


            


            <div className="process">           
            <div className="process-left">
            <h4>Answering the many many questions to plan and prepare for the workshop.</h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Planning a workshop is thinking backwards. Visualise the ideal result at the beginning.  
              </p> 
                         
              </div>
            </div> 

            <div className="process">           
              <div className="process-left">
              <br />
              <br />

              <h4>outcomes</h4>
              <p>What deliverable will I produce at the end?</p>
              <br />
              
              <h4>Participants</h4>
              <p>What expertise do I need to be present in the workshop? Topic promoters? 
                Domain experts? Managers? Local context?</p>
              <p>How many representatives of one role or function do you need? 
                How will I ensure equality? How will I ensure collaboration? Managing 
                loudest voice and silent thinkers?</p>
              <br />

              <h4>Logistics</h4>
              <p>Visas, time, date, location, workshop space? Test prints? 
                Font size? Final Print-outs? Post-its? Markers? Food, allergies, 
                dietary requirements? Veg? No Carbs! Reminders to send? Costs?</p>
              <br />

              <h4>Pre workshop expectations</h4>
              <p>Preparing agenda, pre-read? setting expectation of what they 
                need to do before the workshop and during?</p>
              <br />

              <h4>Workshop format and structure</h4>
              <p>What will be the format of the workshop? Online, Offline, 
                or Hybrid? Several hours or full-day?</p>
              <p>What will participants do in the workshop? Warm ups? 
                Energisers? Walking around time? Individual time? time 
                to collaborate all together or split into working groups?</p>
              <p>What will each exercise be like? How much time will 
                participants need for each exercise? where will participants 
                get the information for each exercise? how will each exercise 
                feed into the next one? what visual structure will help the 
                team to contribute?</p>
              <p>How to avoid people being distracted? How to manage time during the workshop?</p>
              <br />

            </div>

            <div className="process-right">
              <div className="image">
              <Img fluid={props.data.tecneprocess6.childImageSharp.fluid} />
              </div>             
              </div>
            </div> 

            

            <div className="changebg">
            <p className="full-textlarge">
            2-day workshop in Jakarta, Indonesia — 
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
            </div>

            <p className="full-textlarge">
            We captured things different teams brought on their board and voted 
            for; and felt was important for them to get to their goals in the next 6 months.
            </p>

            <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess13.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess11.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess12.childImageSharp.fluid} />
            </Slider>

            </div>
          </section>



          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
            <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneoutcome49.childImageSharp.fluid} />
              </div>
              </section>
              {/* <span className="project-step-number">03</span>
              <h2>the outcome</h2> */}
              <h3>
              The outcome of the Blueprinting Workshop was a driving factor for change within the organisation. 
              </h3>
              <h3>
              While formulating the budget for 2024, the leadership team recognised that redesigning the 
              role of the Activation Coordinators was going to be a key area of focus for the entire org;
               resulting in a major change and pivot in the Strategy & Business goals for 2024.
              </h3>

              
              
            </div>
          </section>

          <section className="process-section">
          <div className="image">
          <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
          </div>
           {/* <div className="image">
          <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
          </div> */}
          <div className="changebg">
          <div className="image">
          <Img fluid={props.data.tecneoutcome47.childImageSharp.fluid} />
          </div>
          <div className="image">
          <Img fluid={props.data.tecneoutcome48.childImageSharp.fluid} />
           </div>
           </div>
          </section>

        </div>
      </div>


      




      {/* remove these breaks below when the prototype code lines are un-commented */}

      




                  {/* commented the below prototype link section till the prototype is made */}


      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
          <div className="changebg">
          <br />
          <br />
          <div className="process">           
            <div className="process-left">
              <h4>
              Prototype of the mobile app
              </h4>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={adobelogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              See Prototype
              </a>
              </div> 
                             
              </div>
            </div>  
          </div>
      </section>
      </div>
      </div> */}



      








      <div className="project-wrapper">
          <div className="project-content">
          {/* <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              The challenge was to identify ways in which our product 
              assists the coaches to do their job better and not to 
              replace their role. 
              </p> 
              <p>
              After collaborating with the coaches we found out our
               USP which was to equip them with factual data which 
               they could not know during their training sessions; 
               such as data about impact strength and area of impact 
               of each hit. 
              </p>               
              </div>
            </div>
            

            <div className="process">           
              <div className="process-right">
              <p>
              Another challenge was to ensure that our product did not
               interfere or distract the current training routines and sessions.
              </p> 
              <p>
              The insights from our research allowed us to design various
               micro & macro interactions that made sure our product 
               complimented the workflow of coaches and trainers. 
               For e.g. Highlight-cards gave quick data insights.
              </p>               
              </div>
            </div>

            <div className="process">           
              <div className="process-right">
              <p>
              Coaches during training need to see live feed of
               data of their trainers and also see data of the 
               elapsed time. The use of bluetooth (to connect 
               and exchange data/information between the gloves
                and mobile app) meant putting considerable strain 
                on the database, resulting in slow loading time.
              </p> 
              <p>
              Our solution was to take snapshots (pictures) of 
              the data during training and store that data locally.
               So  if the user was to scroll back in the timeline, 
               either during or at the end of the training session,
                instead of calling the data from the server we simply
                 showed the snapshots thus eliminating any loading time. 
              </p>               
              </div>
            </div>

            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              Getting people to talk about an important, but highly complex topic was difficult.    
              </p> 
              <p>
              We approached people in different ways (short survey, contextual interview, 
              phone conversation, in-person chats) depending on their comfort. Our 
              strategy involved raising awareness around the topic, and being open 
              to listening to stories about people's personal experiences. 
              </p>                
              </div>
            </div>
          </div> */}
          
          
          
          <p className="full-text">
          Thank you & keep collaborating! <span role="img" aria-labelledby="tecneemoji">🤝🚀</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          
          
          
          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


        </div>
      </div>


      <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-acrole">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Redesigned the field-ops personnel
                     role by leveraging AI-enabled 
                     digital tools and optimizing offline & online 
                     processes to reduce costs and improve 
                     service quality.
                    </div>
                    <br />
                    <p className="maisontags-card">Research, UX & Business Strategy, Service Design, User Experience, Digital UX/UI</p>
                    
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic04} alt="" />
                </div>
              </a>
            </div>
      </section>

  </div>
  </Layout>
)
}

export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/blueprint/cover/blueprint-cover.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/tecne-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge: file(
      relativePath: { eq: "projects/service/blueprint/challenge/1.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge2: file(
      relativePath: { eq: "projects/service/blueprint/challenge/2.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess2: file(
      relativePath: { eq: "projects/service/blueprint/process/2.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/blueprint/process/3.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/blueprint/process/4.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/blueprint/process/5.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/blueprint/process/6.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/blueprint/process/7.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/blueprint/process/8.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/blueprint/process/9.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/blueprint/process/10.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/blueprint/process/11.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/blueprint/process/12.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/blueprint/process/13.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/blueprint/process/14.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/tecne/process/15.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/tecne/process/16.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/tecne/process/17.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/tecne/process/18.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/tecne/process/19.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/tecne/process/20.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/tecne/process/21.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/tecne/process/22.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/tecne/process/23.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/tecne/process/24.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/tecne/process/25.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/tecne/process/26.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/tecne/process/27.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/tecne/process/28.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/tecne/process/29.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/tecne/process/30.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/tecne/process/31.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/tecne/process/32.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/tecne/process/33.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/tecne/process/34.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/tecne/process/35.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/tecne/process/36.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/tecne/process/37.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/tecne/process/38.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/tecne/process/39.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/tecne/process/40.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/tecne/process/41.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/tecne/process/42.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/tecne/process/43.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome45: file(
      relativePath: { eq: "projects/service/blueprint/outcome/10.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome46: file(
      relativePath: { eq: "projects/service/blueprint/outcome/11.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome47: file(
      relativePath: { eq: "projects/service/blueprint/outcome/12.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome48: file(
      relativePath: { eq: "projects/service/blueprint/outcome/9.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome49: file(
      relativePath: { eq: "projects/service/blueprint/outcome/13.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/tecne-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
